import React from 'react';
import { graphql } from 'gatsby';

import { Header, TPWrapper } from '../styles';
import Layout from '../components/Layout';
import MetaData from '../components/meta/MetaData';

const ReferralsTerms = ({ data }) => {
	const terms = data.allGoogleReferralsTermsSheet.edges;

	const metaDescription = data.allGoogleContentNewSheet.edges.find(
		({ node: { position } }) => position === 'landing-meta-description'
	).node.text;

	return (
		<Layout>
			<MetaData
				title="Envel - Referral Program Terms and Conditions"
				description={`Referral Program Terms and Conditions for Envel - ${metaDescription}`}
				image="images/social/social.png"
			/>

			<Header center>Envel Referral Program Terms and Conditions</Header>
			{terms.map(({ node: { content } }, i) => (
				<TPWrapper key={i + 'referralTerms'} dangerouslySetInnerHTML={{ __html: content }} />
			))}
		</Layout>
	);
};

export default ReferralsTerms;

export const referralsTermsQuery = graphql`
	query {
		allGoogleReferralsTermsSheet {
			edges {
				node {
					content
				}
			}
		}
		allGoogleContentNewSheet {
			edges {
				node {
					position
					text
				}
			}
		}
	}
`;
